import React from 'react'
import { Link } from 'react-router-dom'

const MobileNavItem = props => {

  return (
    <>
      {
        props.children 
        
        ? <>
            <div className="mobile-nav-item">
              <li style={{"color": "#626262"}}>{props.label}</li>
            </div>
            <ul>
              {
                props.children.map(child => (
                  <div key={child} className="mobile-nav-item" onClick={props.closeNav}>
                    <Link to={`/${props.label}/${child}`}>
                      <li>{child}</li>
                    </Link>
                  </div>
                ))
              }
            </ul>
          </>
        
        : <div className="mobile-nav-item" onClick={props.closeNav}>
            <Link to={`/${props.label}`}>
              <li>{props.label}</li>
            </Link>
          </div>
      }
    </>

  )

}

export default MobileNavItem
