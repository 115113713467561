import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import NavItem from './navItem'
import NavItemExpand from './navItemExpand'
import MobileNavItem from './mobileNavItem'
import Gallery from './gallery'
import Contact from './contact'
import galleryData from '../galleryData'
import { getImageURL } from '../globals'
import MenuRoundedIcon from '@material-ui/icons/MenuRounded'
import { IconButton, Paper, Drawer } from '@material-ui/core'

const ContentContainer = () => {

  const navigation = [
    {label: 'home'},
    {label: 'projects', children: Object.keys(galleryData).filter(x => x !== 'home')},
    {label: 'contact'}
  ]

  const [mobileNav, setMobileNav] = useState(false)

  return (
    <div className="content-container">

      <div className="content-container-body">
        <div className="content-container-navigation">
          <div className="logo-container">
            <img id="logo" src={getImageURL("logo-condensed.png")} alt="logo" />
          </div>

          <ul className="navigation-ul">

            {
              navigation.map(ni => {
                if('children' in ni) {
                  return <NavItemExpand key={ni.label} label={ni.label} children={ni.children} />
                } else {
                  return <NavItem key={ni.label} label={ni.label} />
                }
              })
            }

          </ul>
        </div>

        <Paper className="content-container-navigation-mobile" elevation={2}>
          <img className="logo-mobile" src={getImageURL("logo-condensed.png")} alt="logo" />
          <IconButton onClick={() => setMobileNav(true)}>
            <MenuRoundedIcon fontSize={"large"} style={{color: "gray"}} />
          </IconButton>
        </Paper>

        <Drawer
          anchor="top"
          open={mobileNav}
          onClose={() => setMobileNav(false)}
        >
          <ul>
            {
              navigation.map(ni => {
                if('children' in ni) {
                  return <MobileNavItem closeNav={() => setMobileNav(false)} key={ni.label} label={ni.label} children={ni.children} />
                } else {
                  return <MobileNavItem closeNav={() => setMobileNav(false)} key={ni.label} label={ni.label} />
                }
              })
            }
          </ul>
        </Drawer>

        <div className="content-container-main">
          <Switch>
            <Route path="/contact" component={Contact} />
            <Route path="/projects/:page" component={Gallery} />
            <Route path="/" component={Gallery} />
          </Switch>
        </div>
      </div>

    </div>
  )
}

export default ContentContainer
