import React from 'react'
import { useParams } from 'react-router-dom'
import galleryData from '../galleryData'
import { getImageURL } from '../globals'

const Gallery = () => {

  let { page } = useParams()

  if(!page) {
    page = 'home'
  }

  const gallery = galleryData[page]

  return (
    <>
      <div className="gallery-container">
        <div className="gallery-img-container">
          {
            gallery.imgs.map(img => (
              <img key={img} className="gallery-img" src={getImageURL(img)} alt={img} />
            ))
          }
        </div>
        <div className="gallery-text-container">
          <div className="gallery-text-top">
            {
              gallery.title
              ? <h2>{gallery.title}</h2>
              : <h2 style={{color: "white"}}>--</h2>
            }
            <span className="copyright">All Images &#169; {new Date().getFullYear()} Sylvia Ritsuko</span>
          </div>
          <div className="gallery-text-bottom">
            {
              gallery.blurb.map((li, i) => (
                <p key={i}>{li}</p>
              ))
            }
          </div>
        </div>
      </div>
      <div className="gallery-container-mobile">
        <div className="gallery-text-mobile">
          {
            gallery.title
            ? <h2>{gallery.title}</h2>
            : <h2 style={{color: "white"}}>--</h2>
          }
          {
            gallery.blurb &&
            gallery.blurb.map((li, i) => (
              <p key={i}>{li}</p>
            ))
          }
        </div>
        <div className="gallery-img-container-mobile">
          {
            gallery.imgs.map(img => (
              <img key={img} className="gallery-img-mobile" src={getImageURL(img)} alt={img} />
            ))
          }
        </div>
        <div className="gallery-text-mobile">
          <span className="copyright">All Images &#169; {new Date().getFullYear()} Sylvia Ritsuko</span>
        </div>
      </div>
    </>
  )

}

export default Gallery
