import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const NavItemExpand = props => {

  const [expand, setExpand] = useState(false)

  const toggleExpand = () => {
    setExpand(!expand)
  }

  return (
    <>
      <li onClick={toggleExpand}>{props.label}</li>
      { expand && (
        <ul>
          {props.children.map(child => (
            <Link key={child} to={`/${props.label}/${child}`}>
              <li>{child}</li>
            </Link>
          ))}
        </ul>
      )}
    </>
  )

}

export default NavItemExpand

