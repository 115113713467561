import React from 'react'

const Contact = () => {
  return (
    <div id="contact">
      <p>
        Please contact Sylvia if you are interested in discussing commission work,
        print acquisition, image licensing information, and exhibition opportunities.
      </p>
      <p>
        <br></br>
        ​phone: (801) 494-3641
        <br></br>
        email: sylviaritsuko@gmail.com
      </p>
    </div>
  )
}

export default Contact