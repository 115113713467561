import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import ContentContainer from './components/contentContainer'

function App() {
  return (
    <Router>
      <div className="App">
        <div className="content-gutter"></div>
        <ContentContainer />
        {/* <p style={{"padding": "2rem", "fontSize": "2rem"}}>
          <b>sylviaritsuko.com</b> is currently under construction
        </p> */}
      </div>
    </Router>
  )
}

export default App
