/*
  STRUCTURE:

    link_title: {
      title: "...",
      blurb: "...",
      imgs: "..."
    }

  DEFINITION:
    
    * link_title: this is used to disitinguish the different data in this
        file. it needs to be unique. aside from "home", it is also what
        appears in the navigation menu under "projects"

    * title: title of the project, appears under the images

    * blurb: short description of the project, appears under the title

    * imgs: list of imagee files to be displayed on the page. these images
        should be located in the /public/media/ folder of this project

*/

const galleryData = {
  "home": {
    "title": "Sylvia Ritsuko Photo",
    "blurb": [],
    "imgs": [
      "scotland_1.jpg", "scotland_2.jpg", "scotland_3.jpg",
      "scotland_4.jpg","scotland_5.jpg", "scotland_6.jpg",
      "scotland_7.jpg", "scotland_8.jpg", "scotland_9.jpg"
    ]
  },
  "faroe islands": {
    "title": "Faroe Islands",
    "blurb": [],
    "imgs": [
      "scotland_1.jpg", "scotland_2.jpg", "scotland_3.jpg",
      "scotland_4.jpg","scotland_5.jpg", "scotland_6.jpg",
      "scotland_7.jpg", "scotland_8.jpg", "scotland_9.jpg"
    ]
  },
  "mitsuami": {
    "title": "三つ編み | Mitsuami",
    "blurb": [
      "Mom would often braid my hair before school, church, birthday parties, piano recitals...the combing, pulling, twisting, yet soothing feeling reminds me of maternal love. 三つ編み Mitsuami, (\"braids\" in Japanese), is about the weaving of three childhoods: my grandmother's, mother's, and mine. I never met my Japanese grandmother, and my mother rarely spoke of her. Hoping to learn more about my maternal connections, I convinced my mother to spend a week in Japan with me, documenting places where the three of us each lived. Together, we re-visited scenes of our childhoods, while realizing that the evidence of them is deteriorating. This series documents those quiet memories."
    ],
    "imgs": [
      "mitsuami_1.jpg", "mitsuami_2.jpg", "mitsuami_3.jpg",
      "mitsuami_4.jpg", "mitsuami_5.jpg", "mitsuami_6.jpg",
      "mitsuami_7.jpg", "mitsuami_8.jpg", "mitsuami_9.jpg",
      "mitsuami_10.jpg", "mitsuami_11.jpg", "mitsuami_12.jpg",
      "mitsuami_13.jpg", "mitsuami_14.jpg", "mitsuami_15.jpg",
      "mitsuami_16.jpg", "mitsuami_17.jpg", "mitsuami_18.jpg",
      "mitsuami_19.jpg", "mitsuami_20.jpg", "mitsuami_21.jpg",
      "mitsuami_22.jpg", "mitsuami_23.jpg", "mitsuami_24.jpg",
      "mitsuami_25.jpg", "mitsuami_26.jpg", "mitsuami_27.jpg",
      "mitsuami_28.jpg", "mitsuami_29.jpg"
    ]
  },
  "saudi": {
    "title": "حَجّ‎ | Pilgrimage",
    "blurb": [
      "The Arabic word حَجّ‎ (Hajj) refers to the holy pilgrimage made by millions of Muslims to Mecca. It means \"to attend a journey\", which connotes both the outward act of a journey and the inward act of intentions. Straying from the traditional route of the Hajj, Pilgrimage documents such a journey made through the desert landscape of Saudi Arabia, where Lehi and his family traveled through the wilderness “by the borders near the shore of the Red Sea” (1 Nephi 2:5) on their way to Bountiful more than 2,500 years ago. Traveling over 2,500 miles with my own family, passing signs directed to Mecca, we marveled at the vast country which has become holy to so many, including ourselves. This pilgrimage helped me develop a reverence for the land, for water, for prophets, for prayer, and the Book of Mormon.", 
      "The images were made using the salt print method, an alternative photographic process invented in the mid-1830s by Henry Fox Talbot, and involves coating paper with a salted solution, sensitizing it with silver nitrate, and exposing the image to light to be embedded into the paper. These particular prints were then soaked in a gold-toning solution to alter their color and further preserve the images’ permanency."
    ],
    "imgs": [
      "pilgrimage_1.jpg", "pilgrimage_2.jpg", "pilgrimage_3.jpg",
      "pilgrimage_4.jpg", "pilgrimage_5.jpg", "pilgrimage_6.jpg",
      "pilgrimage_7.jpg", "pilgrimage_8.jpg", "pilgrimage_9.jpg"
    ]
  }
}

export default galleryData