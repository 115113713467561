import React from 'react'
import { Link } from 'react-router-dom'

const NavItem = props => {

  return (
    <Link to={`/${props.label}`}>
      <li>{props.label}</li>
    </Link>
  )

}

export default NavItem